import AbsoluteCenter from "@/components/generic/absoluteCenter";
import { ButtonWrapper } from "@/components/generic/form/buttonWrapper";
import CredentialsFormWrapper from "@/components/generic/form/credentialsFormWrapper";
import Password from "@/components/generic/form/password";
import SEO from "@/components/generic/seo";
import { useLoginMutation, UserDocument, useUserQuery } from "@/generated";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation } from "@reach/router";
import { Link, navigate, PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

type LoginPageProps = PageProps;

type HasRedirect = { redirect: string };

function hasRedirect(obj: unknown): obj is HasRedirect {
  return (obj as HasRedirect).redirect !== undefined && typeof (obj as HasRedirect).redirect === "string";
}

const LoginPage: React.FC<LoginPageProps> = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [login, { loading }] = useLoginMutation();
  const { state } = useLocation();
  const { data } = useUserQuery();

  useEffect(() => {
    if (data?.authenticatedItem) navigate("/");
  }, []);

  return (
    <AbsoluteCenter maxWidth="sm">
      <SEO title="Login" />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setError(null);

          if (username && password) {
            const result = await login({
              variables: { email: username, password },
              update: (cache, result, context) => {
                if (result.data?.authenticateUserWithPassword?.__typename === "UserAuthenticationWithPasswordSuccess") {
                  const user = result.data.authenticateUserWithPassword.item;

                  cache.writeQuery({ query: UserDocument, data: { authenticatedItem: { ...user } } });
                }
              },
            }).catch((e) => console.log(e));

            if (
              result &&
              result.data?.authenticateUserWithPassword?.__typename === "UserAuthenticationWithPasswordSuccess"
            ) {
              const redirect = hasRedirect(state) ? state.redirect : "/";
              navigate(redirect, { state: { redirect: null } });
            }

            if (
              result &&
              result.data.authenticateUserWithPassword.__typename === "UserAuthenticationWithPasswordFailure"
            ) {
              setPassword("");
              setError(result.data.authenticateUserWithPassword.message);
            }
          }
        }}
      >
        <CredentialsFormWrapper elevation={7}>
          <Typography variant="h5" component="span">
            Login
          </Typography>
          <TextField
            type="text"
            value={username}
            label="e-mail"
            onChange={(e) => setUsername(e.currentTarget.value)}
            disabled={loading}
          />
          <Password
            id="password-input"
            value={password}
            label="wachtwoord"
            onChange={(e) => setPassword(e.currentTarget.value)}
            disabled={loading}
          />
          {error && (
            <Typography variant="caption" component="p" color="error.main">
              {error}
            </Typography>
          )}
          {loading && (
            <Typography variant="caption" component="p" color="primary.light">
              Logging in...
            </Typography>
          )}
          <ButtonWrapper>
            <Button variant="text" component={Link} to="/reset-wachtwoord/" color="tertiary" disabled={loading}>
              Wachtwoord vergeten?
            </Button>
            <Button variant="contained" type="submit" disabled={loading}>
              Login
            </Button>
          </ButtonWrapper>
        </CredentialsFormWrapper>
      </form>
    </AbsoluteCenter>
  );
};

export default LoginPage;
